.case {
  background-color: #33d67c;
}

.live {
  font-family: "DM Sans" !important;
  font-weight: 700 !important;
  color: #1e1e1e !important;
  font-style: normal !important;
  text-align: start;
  padding-top: 10px !important;
}

.most {
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  color: #1e1e1e !important;
  font-style: normal !important;
}

.combine {
  display: flex !important;
  padding-bottom: 10px !important;
}

.line {
  text-decoration: underline;
}
@media (max-width: 580px)  {
   .combine{
    display: block !important;
   } 
   .live{
    margin-left: 0px !important;
   }
   .combine{
    margin-left: 0px !important;
   }
   /* .case{
margin-right: -40px;
   } */
}