.gallary-img{
    width: 350px !important;
    height: 350px !important;
}

@media (max-width: 650px) {
    .gallary-img {
        width: 100% !important;
        height: 100% !important;
    }
  }