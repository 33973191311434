.divider_card{
    color: red !important;
}
.summary_card_title{
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: DM sans !important;
}
.summary_card_subTitle{
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: DM sans !important;
}

.mainGridBox_summary {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 12px !important;
    width: auto !important;
    max-width: 245px !important;
    background: rgba(255, 255, 255, 0.05) !important;
    padding: 6px 11px 6px 11px !important;
    border-radius: 8px !important;
}
.image_summary{
    
    /* margin-left: 24px !important; */
    display: inherit;
   
}

.dashbtn {
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    color: #0D0D0D !important;
    border: 1px solid #fac915 !important;
    border-radius: 24px !important;
    font-family: DM Sans;
    font-size: 14px !important;
    line-height: 14px !important;
    font-weight: bold !important;
    width: 200px !important;
    height: 44px !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}