.custom-contain {
  font-family: "DM Sans", sans-serif !important;
}
.home-get-started-btn {
  background: #fb8e0b !important;
  border: 1.35px solid #f36905 !important;
  border-radius: 27px !important;
  height: 54px !important;
  width: 210px !important;
  color: #fff !important;
  font-weight: 700 !important;
  z-index: 30 !important;
  text-transform: capitalize;
}
.distribute-text {
  font-size: 2.75rem !important;
  max-width: 80% !important;
  line-height: 1.3 !important;
  font-family: "DM Sans" !important;
  position: relative;
  margin-left: 30px !important;
  font-weight: 700 !important;
  background-color: transparent !important;
  z-index: 100 !important;
}
.filter {
  z-index: 100 !important;
}
.heading-data {
  position: relative;
}
.title-circle {
  position: absolute;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: linear-gradient(180deg, #facc15 0%, #fb8e0b 100%);
  z-index: 1 !important;
  border-radius: 50%;
}
.title-circle2 {
    position: absolute;
    width: 38px;
    height: 38px;
    /* left: 610px;
    top: -25px; */
    left: 80%;
    top: -25%;
    border-radius: 50%;
    background: linear-gradient(180deg,#facc15 0,#fb8e0b 100%);
  }
.title-circle3{
    /* position: absolute; */
    width: 18px;
    height: 18px;
    /* left: 700px;
    top: -25px; */
    border-radius: 50%;
    background: linear-gradient(180deg,#facc15 0,#fb8e0b 100%) !important;
}