.artist-number-banner {
  background-image: url("../../assets/img/static/Livent\ Events\ Website.png");
  padding: 2rem;
  background-size: cover;

}
.inner {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  /* font-size: 3rem !important; */
  line-height: 150% !important;
}
.Number-item-label{
    text-align: center;   
}
.Number-item{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.9375rem;

}
