.cover_title{
    font-size: 24px !important;
    font-weight: 700 !important;
    font-family: DM sans !important;
}

.take_sale{
    text-align: center !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    font-family: DM sans !important;
    margin-bottom: 10px !important;

}

.paybtn{
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    /* justify-content: center !important; */
    /* margin: auto !important; */
    color: #0D0D0D !important;
    border: 1px solid #fac915 !important;
    border-radius: 24px !important;
    font-family: DM Sans;
    font-size: 14px !important;
    line-height: 14px !important;
    font-weight: bold !important;
    width: 150px !important;
    height: 44px !important;
    margin-top: 20px !important;
    margin-bottom: 40px !important; 
}