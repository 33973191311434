.bannerBackground {
  padding-top: 15px !important;
  background-color: white !important;
}
.nav-item-data{
  top:0;
  position: fixed;
  background-color: white !important;
  z-index:100;
}

.customNavitems {
  margin-right: 30px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  color: black !important;
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
}

.customNavitems:hover {
  box-shadow: none !important;
}

.customNavitems:nth-last-child(2) {
  margin-right: 0px !important;
  color: white;
}

.customNavitems:nth-last-child(1) {
  margin-left: 40px !important;
  margin-right: -4px !important;
  background-color: #fb8e0b !important;
  border-radius: 2rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-family: "DM Sans";
  color: white !important;
}
.smalldiv {
  height: 3px !important;
  width: 45px !important;
  background: #fb8e0b !important;
  border-radius: 2.5px !important;
  display: none !important;
}

.navactive ~ .smalldiv {
  display: block !important;
}

.contectUs {
  margin-left: "67.5px" !important;
  color: "#FFFFFF";
  text-transform: "capitalize" !important;
  background-color: "#3E936B" !important;
  box-shadow: none !important;
}
