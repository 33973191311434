.dashcard-link{
    text-decoration: none !important;
    color: white !important;
}

.dashboard-card-img{
    width: 41px !important;
    height: 41px !important;
}
.rupee-icon{
    padding-top: 5px !important;
}

.dashboard-card-icon{
font-size: 18px;
}
.dashboard-icon-box{
    width: 40px !important;
    height: 40px !important;
}
.dashboard-card-title{
    font-size: 16px !important;
}

@media screen and (max-width:900px) {
    .dashboard-card-img{
        width: 20px !important;
        height: 20px !important;
    }
    .dashboard-card-icon{
        font-size: 10px;
        }
        .dashboard-icon-box{
            width: 20px !important;
            height: 20px !important;
        }
        .dashboard-card-title{
            font-size: 12px !important;
        }
}