.error-page-div {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.error-page-div img {
    width: 100%;
    height: 100vh;
}


#error-first-text {
    max-width: 100%;
    color: black !important;
    font-family: DM Sans !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 47px !important;
    text-align: center !important;
    position: absolute;
    top: 25px;
    left: 45%;
}

#error-second-text {
    max-width: 100%;
    color: black !important;
    font-family: DM Sans !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 47px !important;
    text-align: center !important;
    position: absolute;
    top: 400px;
    left: 40%;
}

#error-third-text {
    max-width: 100%;
    color: black !important;
    font-family: DM Sans !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    text-align: center !important;
    position: absolute;
    top: 490px;
    left: 35%;
}

#errorPageButton {
    position: absolute;
    top: 490px;
    left: 35%;
}


@media screen and (min-width: 360px) {


    #errorPageButton {
        margin-top: 10px;
        margin-left: -1px;
    }
}


@media screen and (min-width: 375px) {

    
    #error-second-text {
        margin-top: -55px;
        margin-left: -90px;
    }

    #error-third-text {
        margin-top: -80px;
        margin-left: -110px;
    }

    #errorPageButton {
        margin-top: -10px;
        margin-left: 5px;
    }
}

@media screen and (min-width: 768px) {

    #error-second-text {
        margin-top: -55px;
        margin-left: -50px;
    }

    #error-third-text {
        margin-top: -80px;
        margin-left: -90px;
    }

    #errorPageButton {
        margin-top: -10px;
        margin-left: 50px;
    }
}

@media screen and (min-width: 1024px) {

    #error-second-text {
        margin-top: -60px;
        margin-left: -30px;
    }

    #error-third-text {
        margin-top: -80px;
        margin-left: -30px;
    }

    #errorPageButton {
        margin-top: -10px;
        margin-left: 90px;
    }
}

@media screen and (min-width: 1440px) {

    #error-second-text {
        margin-top: -40px;
        margin-left: 10px;
    }

    #error-third-text {
        margin-top: -60px;
        margin-left: 10px;
    }

    #errorPageButton {
        margin-top: 0px;
        margin-left: 150px;
    }
}

@media screen and (min-width: 1535px) {

    /* #error-first-text {
        margin-top: 100px;
        margin-left: 30px;
    } */

    #error-second-text {
        margin-top: -10px;
        /* margin-left: 110px; */
    }

    #error-third-text {
        margin-top: -40px;
    }
/* 
    #errorPageButton {
        margin-top: 400px;
        margin-left: 300px;
    } */
}

@media screen and (min-width: 2560px) {

    #error-second-text {
        margin-top: 250px;
        margin-left: 110px;
    }

    #error-third-text {
        margin-top: 250px;
        margin-left: 170px;
    }

    #errorPageButton {
        margin-top: 350px;
        margin-left: 290px;
    }
}