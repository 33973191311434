.profile-heading {
  color: white !important;
}
.homemanageBoldTextabout {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 60px;
  text-transform: capitalize;
  color: white;
  margin-bottom: 30px;
}
.homemanageNormalText {
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  color: white;
  margin-bottom: 16px;
}
.musician {
  min-width: 160px !important;
  height: 31px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 66px !important;
  padding: 5px 30px !important;
  color: white !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  font-family:'DM Sans' !important;
}
.image-banner-port {
  width: 320px !important;
  height: 320px !important;
}
.banner-signature{
  width:230px !important;
  height: 78px !important;
}

@media (max-width: 900px) {
  .musician {
    margin-top: 10px !important;
  }
  .image-banner-port {
    width: 290px !important;
    height: 290px !important;
  }
}


@media (max-width: 580px) {
  .homemanageBoldTextabout {
    font-size: 30px;
    line-height: 40px;
  }
  .image-banner-port {
    width: 220px !important;
    height: 220px !important;
  }
  .banner-signature{
    width:200px !important;
    height: 78px !important;
  }
  .musician {
    margin-top: 10px !important;
  }
}

.editFormBtn{
  background-color: #f9a40d !important;
  border: none !important;
  border-radius: 60px !important;
  color: black !important;
  font-weight: 600 !important;
  font-family:'DM Sans !important'
}