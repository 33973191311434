.master-service {
  background-image: url("../../assets/img/static/price-back.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 60vh;
}

.musicMasteringUpperText {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 40px !important;
  line-height: 140% !important;
  text-align: center !important;
  color: #ffffff !important;
  margin-bottom: 40px !important;
  /* padding: 10px; */
}
.musicMasteringAboutUsBtn {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  letter-spacing: 0.01em !important;
  -webkit-text-decoration-line: underline !important;
  text-decoration-line: underline !important;
  text-transform: capitalize !important;
  color: #fb8e0b !important;
}
.musicMasteringBox {
  aspect-ratio: 16/9;
  width: 631px;
  min-width: 100px;
  background: #ffffff !important;
  border: 0.484509px solid #cbcbcb !important;
  box-shadow: 0px 1.93804px 9.69018px rgba(119, 198, 242, 0.4) !important;
  border-radius: 9.69018px !important;
  padding: 0px 20px 30px 20px !important;
  margin-bottom: 102px;
}
@media (max-width: 820px) {
  .musicMasteringBox {
    margin-bottom: 70px;
  }
}
@media (max-width: 650px) {
  .musicMasteringBox {
    margin-bottom: 50px;
  }
}
@media (max-width: 480px) {
  .musicMasteringBox {
    margin-bottom: 40px;
    padding: 0px 10px 14px 10px !important;
  }
}

.musicMasteringBox iframe {
  border-radius: 10px !important;
  border: none !important;
  height: 100% !important;
  width: 100% !important;
}
.manageBlueDotContainer {
  display: -webkit-box !important;
  margin-top: 20px;
  gap: 8px;
}
.manageBlueDot {
  width: 8.04px;
  height: 8.04px;
  background: #0563f1;
  box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.manageDot {
  width: 8.04px;
  height: 8.04px;
  box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
