audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: gray;
}
.listenimage {
  height: 226px !important;
}


@media screen and (max-width:1330.5px) {
  .listenarrow{
    display: none !important;
  } 
}

