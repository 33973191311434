#master-stack {
  margin-top: 80px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#master-box {
  max-width: 1100px;
}

/* #accordian-container-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

#card-out-heading {
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 1.625rem;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  line-height: 2rem;
}

#accordian-parent {
  background-color: white;
  color: black;
  border-bottom: 1px solid black;
}

#accordiansummary-heading {
  padding: 7px;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 1000;
  line-height: 24px;
}

#accordiandetails-content {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 32px;
}

#accordiandetails-content-withoutList {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 17px;
}

#accordiandetails-content-withTextandList {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 17px;
}

#accordianList {
  padding: 0px 32px;
}

.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root {
  border-bottom: 1px solid black !important;
}

.css-1dlgten-MuiPaper-root-MuiCard-root {
  background-color: white !important;
  color: black !important;
}

@media (max-width: 480px) {
  .custom-react-player {
    height: 100% !important;
  }
}
