.color-custom{
    /* color: green !important; */
    background: rgba(32, 83, 228, 0.2) !important;
    color:  #2053E4 !important;
    ;
}
.color-ditrub{
    color: #33D67C !important;
    background-color: rgba(51, 214, 124, 0.2)!important;
}
.color-black{
    /* color:pink; */
    color: white !important;
    background-color: pink !important;
}
.hello{
    color:#FBCE2E !important;
    background: rgba(251, 206, 46, 0.2)!important;
  }
  
  .color-black{
    background: rgba(231, 55, 55, 0.2) !important;
    color:#E7373A !important;
    
  }
  .color-pink{
    background: rgba(228, 32, 181, 0.2) !important;
    color: #E420B5 !important;

  }
  
  .select-status{
    font-size: 12px !important;
    font-family: DM sans;
  }