.artistbtn {
    background-color:#f9a40d  !important;
    color: #0D0D0D !important;
    padding: 10px, 22px, 10px, 22px !important;
    border-radius: 24px !important;
    /* / //styleName: Body/B4 Bold; / */
    font-family: DM Sans !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    border: none !important;
    text-align: right !important;
    margin-top: 16px !important;
    /* display: flex-end; */
    /* justify-content: center; */
    /* align-items: center; */
    /* letter-spacing: 0em !important;
    text-align: center !important; */

}

.artistbtn:hover{
    border: none !important;

    background-color: #f9a40d  !important;
    color: #0D0D0D !important;
    padding: 10px, 22px, 10px, 22px !important;
    border-radius: 24px !important;
    /* / //styleName: Body/B4 Bold; / */
    font-family: DM Sans !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    /* letter-spacing: 0em !important;
    text-align: center !important; */
}

.bankbtn{
    text-decoration: none !important;
}