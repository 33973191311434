.rapperVideo :hover  {
    background: #BE0081;
 
}

/* .playimage:hover .rapperVideo  {
    background: #BE0081;
} */
.rapperVideo :hover .playimage   {
    scale: 2;
    transition: 2s;
    
}
.rapperVideo .playimage   {
    scale: 1;
    transition: 2s;
    
}

.playvideo{
    font-family: 'Poppins';
    /* border-bottom: 1px solid;     */
    /* width: 100px; */
}
.rapperVideo :hover .playvideo{
opacity: 0;
transition: 1s;
}
 /* .eddtBtn:hover{
    background-color: #00D8C0;
} */


.videosSlider .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 11px;
    height: 11px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #FCE23B;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.videosSlider .slick-dots li.slick-active button:before {
    opacity: .75;
    color: transparent;
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 4px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #0009;
    background-color: rgba(0,0,0,0);
    border: 2px solid #FCE23B;
    border-radius: 10px;
}

.videoplayer{
    width: 600px;
    height: 400px;
}

@media screen and (max-width:800px) {
    .videoplayer{
        width: 400px !important;
        height: 300px;
    }
}


@media screen and (max-width:500px) {
    .videoplayer{
        width: 100% !important;
        height: 300px;
    }
}
.css-1to9c10-MuiPaper-root-MuiDialog-paper {
    overflow:  hidden !important;
    background-color: transparent;
}








.containerlatest {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

}

.imagelatest {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}

.containerlatest:hover .imagelatest {
  transform: scale(1);
}

.overlaylatest {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

.containerlatest:hover .overlaylatest {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.5s;
}

.textlatest {
  font-size: 20px;
}
