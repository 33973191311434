.submitprofilebtndree {
  background-color: #f9a40d !important;
  border: none !important;
  color: black !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "DM Sans", sans-serif !important;
  border-radius: 55px !important;
  width: 112px !important;
  height: 42px !important;
  margin-top: 30px !important;
}
.circlebar {
  background: linear-gradient(180deg, #facc15, #fb8e0b) !important;
  /* border-radius: 50% !important; */
  /* height: 4px !important; */
  /* width: 18px !important; */
  /* margin-top: -8px; */
}
/* @media screen and (min-width: 2065px) {
.slick-slide{
  width: 2065px !important;
}
} */
@media screen and (min-width: 965px) {
  #cardevent {
    height: 230px !important ;
  }
}
@media screen and (max-width: 965px) {
  .imagesongvalue {
    width: 100% !important;
    margin-top: -24px;
    height: 300px;
  }
  .submitprofilebtndree {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 565px) {
  .imagesongvalue {
    width:100% !important;
    margin-top: -24px;
  }
  .submitprofilebtndree {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    margin-left: 10px !important;
  }
  .arrow-drive{
    left: 10% !important;
  }
}
