.custom-list {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 56px !important;
  color: #343a40 !important;
  text-align: center;
}
.link-btn{
  color: #b18aff !important;
  text-decoration: none;
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 30px !important;
}
