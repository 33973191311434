.test-background{
    background-image: url("../../assets/img/static/Testimonial.png");
    min-height: 500px;
    position: relative;
    padding-block: 20px 40px;
    padding-inline: 0;
    margin-top: 6.25rem;
    background-size: cover
}
.testimonial-heading {
    text-align: center;
    margin-bottom: 50px;
   
  }
  .testimonial-heading-para{
    font-family: 'DM Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 48px !important;
    line-height: 58px !important;
  }
  .title-testmonial{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    text-align: center !important;
    color: #fd6003 !important;
    margin-bottom: 20px !important;
  }
  .description{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    text-align: center !important;
    color: #383a47 !important;
    opacity: .7 !important;
    text-align: center  !important;
  }
  .card-item::after {
    content: "";
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translate(-50%,0) rotate(45deg);
  }

  @media screen and (max-width:900px) {
    .card-item::after {
      display: none;
      content: "";
      width: 2rem;
      height: 2rem;
      background-color: #fff;
      position: absolute;
      bottom: 18%;
      left: 50%;
      transform: translate(-50%,0) rotate(45deg);
    }
  }
  @media screen and (max-width:1200px) {
    .card-item::after {
      content: "";
      width: 2rem;
      height: 2rem;
      background-color: #fff;
      position: absolute;
      bottom: 15%;
      left: 50%;
      transform: translate(-50%,0) rotate(45deg);
    }
  }