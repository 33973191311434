 .homemanageBoldTextusp {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: rgba(23, 23, 23, 0.9);
    margin-bottom: 30px;
  }
  @media (max-width: 580px) {
    .homemanageBoldTextusp {
      font-size: 30px;
      line-height: 40px;
    }
  }
   
  .homemanageNormalTextaboutusp {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: black !important;
    margin-bottom: 16px !important;
  }
  @media (max-width: 580px) {
    .homemanageNormalTextaboutusp {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .manageimgBoxaboutusp {
    /* width: 300px; */
    background: #ffffff !important;
    border: 0.484509px solid #cbcbcb !important;
    box-shadow: 0px 1.93804px 9.69018px rgba(119, 198, 242, 0.4) !important;
    border-radius: 9.69018px !important;
    padding: 0px 20px 30px 20px !important;
    text-align: start;
  }
  @media (max-width: 480px) {
    .manageimgBoxaboutusp {
      padding: 0px 10px 30px 10px !important;
    }
  }
  
  .manageBlueDotContainerabout {
    height: 30px !important;
  }
  
  .manageimgBoxaboutusp img {
    border-radius: 8px !important;
  }
  
  .manageBlueDot {
    width: 8.04px;
    height: 8.04px;
    background: #0563f1;
    box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  
  .manageDot {
    width: 8.04px;
    height: 8.04px;
    box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  .usp-li-dot-usp {
    height: 14px;
    width: 14px;
    background-color: #fb8e0b;
    border-radius: 50%;
  }