
.accountcard{
    padding: 20px !important;
    border-radius: 10px !important;
    margin-top: 10px;
   
}
.basicdetails{
    font-size: 1.625rem !important;
    font-family: DM sans !important;
    font-weight: 700 !important;
    line-height: 2rem !important;
    color: '#fff';
}
.profilephoto{
    font-family: DM sans !important;
    font-size: 0.75rem !important; 
    font-weight: 700 !important;
    line-height: 1rem !important;
}
/* social media details css------- */
.socialmedia{
    font-size: 1.625rem !important;
    font-family: DM sans !important;
    font-weight: 700 !important;
    line-height: 2rem !important;
    color: '#fff';
}
/* help center */
.linkpage{
    color: wheat !important;
    text-decoration: none !important;
}
.submitButtoninprofile{
    text-transform: capitalize !important;
}
 
 /* #birthday{
    border-style: none !important; 
    background-color:"rgba(255, 255, 255, 0.05) !important" !important;
    height:"44px",!important;
    border-bottom: none  !important;
 
 } */
 #fontfamilys{
    font-family: 'DM Sans', sans-serif !important;
    font-style: normal !important;
    font-size: 16px;
    line-height: 21px;
 }
 .MuiOutlinedInput-notchedOutline{
    border: none !important;
    
 }
