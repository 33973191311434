.theme {
  position: absolute;
  /* top: 0%; */
  left: 80px;
  top: 100px;
}
.theme-btn {
  display: none !important;
}
.main-port-box {
  width: 100% !important;
  height: 100% !important;
}
.main-port-box :hover .theme-btn {
  display: block !important;
  background-color: black !important;
  font-weight: 700 !important;
  width: 180px !important;
  font-size: 16px !important;
  color: white;
  height: 50px !important;
  text-transform: capitalize;
}
.theme-btn-purchase {
  background-color: black !important;
  font-weight: 700 !important;
  width: 180px !important;
  font-size: 16px !important;
  color: white !important;
  height: 50px !important;
  text-transform: capitalize !important;
}

.prrviewbtn {
  background-color: #f9a40d !important;
  color: black !important;
  text-transform: capitalize !important;
  border-radius: 66px !important;
  width: 200px !important;
  margin-top: 15px !important;
  font-weight: 600 !important;
}
