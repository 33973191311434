.basic-song-drop {
    /* border: 2px dashed white !important; */
    border-radius: 4px !important;
    max-width: 100% !important;
    height: 100% !important;
    display: flex !important;
    line-height: 15px !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    letter-spacing: 4px;
    background: linear-gradient(to right, #A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#A7A5A4 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 22px 2px, 2px 22px;

}

.upload-song {
    background: rgba(255, 255, 255, 0.05) !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    font-size: 18px !important;
    height: 33px !important;
    width: 169px !important;
    color: white !important;
    font-weight: 700 !important;
    margin-top: 10px !important;
    line-height: 15px !important;

}

.drop-song {
    font-size: 18px !important;
    font-weight: 700 !important;
    margin-top: 20px !important;
    line-height: 15px !important;
    text-align: center !important;
    padding: 0px 15px 0px 15px !important;

}

.support {
    color: #A7A5A4 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-top: 12px !important;
    line-height: 15px !important;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.support2 {
    color: #A7A5A4 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-top: 12px !important;
    line-height: 15px !important;
    margin-bottom: 20px !important;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

.quality {
    color: #A7A5A4 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-top: 12px !important;
    line-height: 15px !important;

    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    max-width: 344px;
}

.basic-btn {
    background-color: #f9a40d !important;
    border: none !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'DM Sans', sans-serif !important;
    border-radius: 15px !important;
    width: 149px !important;
    height: 28px !important;
    margin-top: 12px;
}

.backbtn{
    background-color:transparent !important;
    border: 1px solid #f9a40d !important;
    color: #f9a40d !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'DM Sans', sans-serif !important;
    border-radius: 55px !important;
    width: 112px !important;
    height: 44px !important;
    margin-top: 12px;
}
.nextbtn{
    background-color:#f9a40d !important;
    border: none !important;
    color: black !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'DM Sans', sans-serif !important;
    border-radius: 55px !important;
    width: 158px !important;
    height: 44px !important;
    /* margin-top: 12px; */
    margin-left: 20px !important;
}
#datePicker{
    border-style: none !important; 
    background-color:"rgba(255, 255, 255, 0.05) !important" !important;
    height:"44px",!important
}
#setTimer{
    border-style: none !important; 
    background-color:"rgba(255, 255, 255, 0.05) !important" !important;
    height:"44px",!important
}
/* label{
    color: red;
} */
.errorhandler{
    color: red !important;
    font-family: DM sans !important;
}

@media screen and (max-Width:768px) {
    .errorhandler{
        color: red !important;
    font-size: 14px !important;
     font-family: DM sans !important;
    }
}

.orange-date {
    color: orange !important;
     background-color: pink !important;
  }
  
  .faded-date {
    opacity: 0.5 !important;
  }

  .orange-date .MuiPaper-root {
    background-color: #eaea87 !important;
  }

  .splash-image{
        /* border: 2px dashed white !important; */
        border-radius: 4px !important;
        max-width: 100% !important;
        height: 100% !important;
        display: flex !important;
        line-height: 15px !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        letter-spacing: 4px;
        background: linear-gradient(to right, #A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#A7A5A4 50%, rgba(255, 255, 255, 0) 0%);
        background-position: top, right, bottom, left;
        background-repeat: repeat-x, repeat-y;
        background-size: 22px 2px, 2px 22px;
    
  }
  .upload-splash{
    background: rgba(255, 255, 255, 0.05) !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    font-size: 18px !important;
    height: 33px !important;
    width: 250px !important;
    color: white !important;
    font-weight: 700 !important;
    margin-top: 20px  !important;
    font-family: 'DM Sans', sans-serif !important;
}

  @media screen and (max-Width:1200px) {
    .splash-image{
        height:300px !important;
    }
  }