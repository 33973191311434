.master-about {
    width: 100%;
    min-height: 60vh;
  }
  
  .aboutMasteringUpperText {
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 60px !important; 
    align-items: center !important;
    text-align: center !important;
    text-transform: capitalize !important;
    color: rgba(23,23,23,.9) !important;
  }
  .musicMasteringAboutBtn {
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 23px !important;
    letter-spacing: 0.01em !important;
    -webkit-text-decoration-line: underline !important;
    text-decoration-line: underline !important;
    text-transform: capitalize !important;
    color: #fb8e0b !important;
  }
  .musicMasteringBoxdata {
    aspect-ratio: 16/9;
    width: 631px;
    min-width: 100px;
    background: #ffffff !important;
    border: 0.484509px solid #cbcbcb !important;
    box-shadow: 0px 1.93804px 9.69018px rgba(119, 198, 242, 0.4) !important;
    border-radius: 9.69018px !important;
    padding: 0px 20px 30px 20px !important;
    margin-bottom: 102px;
    position: relative;
  }
  @media (max-width: 820px) {
    .musicMasteringBoxdata {
      margin-bottom: 70px;
    }
  }
  @media (max-width: 650px) {
    .musicMasteringBoxdata {
      margin-bottom: 50px;
    }
  }
  @media (max-width: 480px) {
    .musicMasteringBoxdata {
      margin-bottom: 40px;
      padding: 0px 10px 14px 10px !important;
    }
  }
  
  .musicMasteringBoxdata iframe {
    border-radius: 10px !important;
    border: none !important;
    height: 100% !important;
    width: 100% !important;
  }
  .manageBlueDotContainerdata {
    display: -webkit-box !important;
    margin-top: 20px;
    gap: 8px;
  }
  .manageBlueDotdata {
    width: 8.04px;
    height: 8.04px;
    background: #0563f1;
    box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  
  .manageDotdata {
    width: 8.04px;
    height: 8.04px;
    box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  .title-circle-about {
    position: absolute;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    background: linear-gradient(180deg, #facc15 0%, #fb8e0b 100%);
    z-index: 1 !important;
    border-radius: 50%;
    left: 19%;
    top: 45%;
  }
  .title-circle-about-data{
    position: absolute!important;
    width: 18px;
    height: 18px;
    top: 108%;
    border-radius: 50%;
    background: linear-gradient(180deg,#facc15 0,#fb8e0b 100%);
  }
  .title-circle-about-data2{
    position: absolute!important;
    width: 18px;
    height: 18px;
    right: 15%;
    top: 44%;
    border-radius: 50%;
    background: linear-gradient(180deg,#facc15 0,#fb8e0b 100%);
  }
  .title-circle-about-data3{
    position: absolute;
    width: 68px;
    height: 68px;
    right: -25%;
    top: 100%;
    border-radius: 50%;
    background: linear-gradient(180deg,rgba(250,204,21,.2) 0,rgba(251,142,11,.2) 100%);
    transform: matrix(1,0,0,-1,0,0);
  }

  @media screen and (max-width:1000px) {
    .title-circle-about-data3{
      right: 0%;
      top:105%;
    }
  }
  @media screen and (max-width:600px) {
    .title-circle-about-data3{
  display: none;
    }
  }
  
  @media  screen and (max-width: 456px) {
    .title-circle-about,.title-circle-about-data,.title-circle-about-data2,.title-circle-about-data3{
      display: none;
    }
  }