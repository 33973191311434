.rcong-first-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rcong-second-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}

.rcong-third-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.rcong-third-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;

}

.royal-card-icon{
    width: 50px !important;
    height: 50px !important;
}

.text-underline{
    text-decoration: none !important;
}

.share-icons{
    margin-left: 20px !important;
}

/* ----------------rcong-first-box  start----------------- */
#cong {
    font-family: DM Sans;
    font-weight: 700;
    font-size: 36px;
    line-height: 46.87px;
    text-align: center;
}

.cong-msg-box {
    max-width: 687px;
    height: 42px;
}

#cong-msg-txt {
    font-family: DM Sans;
    font-weight: 700;
    font-size: 16px;
    line-height: 20.83px;
    text-align: center;
}

/* ----------------rcong-first-box  End----------------- */



.pto {
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    color: #0D0D0D !important;
    border: 1px solid #fac915 !important;
    border-radius: 8px !important;

    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 700 !important;

    
}

.backbtn {
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    color: #0D0D0D !important;
    border: 1px solid #fac915 !important;
    border-radius: 24px !important;
    font-family: DM Sans;
    font-size: 14px !important;
    line-height: 14px !important;
    font-weight: bold !important;
    width: 200px !important;
    height: 44px !important;
    margin-top: 12px;
}

.dashboard-btn{
    text-decoration: none !important;
}