#commQ-master-stack {
    background-color: #fb8e0b;
    min-height: 500px;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;

}

#commQ-master-box {
    /* margin: 20px 100px; */
}

#commQ-main-heading {
    font-size: 50px;
    font-family: DM Sans;
    font-weight: 700;
    line-height: 3rem;
    margin-bottom: 10px;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

#commQ-accordian-parent {
    background-color: white;
    color: black;
}

#commQ-accordiansummary-heading {
    font-size: 20px;
    font-weight: 400 !important;
    padding: 5px;
}

/* 
.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root {} */

#commQ-accordian-summary {
    border-bottom: 1px solid white !important;
    background-color:#f3f4f6 ;
}

.css-qoi9-MuiPaper-root-MuiAccordion-root:before {

    background-color: white !important;
    border: 1px groove white !important;

}

.css-bj2p8a-MuiPaper-root-MuiCard-root{
    background-color: white !important;
}

.css-qoi9-MuiPaper-root-MuiAccordion-root{
    box-shadow: none !important;
}