* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.extra {
    border-radius: 39px !important;
    padding: 10px 23px !important;
    background: #2b2b2b !important;
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    margin: auto !important;
    color: #f4a50f !important;
    border: 1px solid #fac915 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    font-family: DM Sans !important;
}

.oneTime {
    border-radius: 24px !important;
    padding: 10px 22px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    color: #111111 !important;
    border: 1px solid #fac915 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 700 !important;
    font-family: DM Sans !important;
}

.plans {
    border-radius: 16px !important;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 30px;
    margin: 48px 0px;
}

@media screen and (max-width: 599px) {
    .plans {
        padding: 16px !important;
        margin: 28px 0px !important;
    }

    .service {
        padding: 12px !important;
    }

    .top {
        font-size: 8.40px !important;
        line-height: 11px !important;
        top: -10px !important;
    }

    li.suit {
        font-size: 9.61px !important;
        line-height: 19.21px;
    }

    .persent {
        font-size: 14.41px !important;
    }

    .cost {
        margin-bottom: 9.61px !important;
    }

    .keep {
        margin-bottom: 19.91px !important;
    }

    .extra {
        padding: 6px 14px !important;
        border-radius: 23px !important;
    }

    .oneTime {
        padding: 6px 13px !important;
        border-radius: 14.41px !important;
    }
}

.choose {
    font-family: DM Sans !important;
    font-weight: 700 !important;
    font-style: normal;
    color: #ffffff;
    font-size: 24px !important;
    line-height: 31px;
}

.service {
    padding: 20px;
    border-radius: 8px !important;
    background-color: rgba(255, 255, 255, 0.05);
}

.upc {
    /* border: 3px solid #fac915; */
    position: relative;
}

.top {
    border-radius: 33px;
    padding: 2px 20px 1px;
    text-align: center;
    background-color: #2b2b2b;
    position: absolute;
    left: -3px;
    top: -15px;
    color: #fba40f;
    border: 2px solid #fac715;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700 !important;
    font-family: DM Sans !important;
}

.one {
    font-weight: 700 !important;
    font-family: DM Sans !important;
    font-style: normal;
    color: #ffffff;
    text-align: center;
}

li.suit {
    font-family: DM Sans !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 32px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.persent {
    font-size: 24px;
    color: #f4a30f !important;
    font-weight: 700 !important;
}

.cost {
    margin-bottom: 16px;
}

.keep {
    margin-bottom: 32px;
}

.content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}