.avtar-logout .MuiTooltip-tooltip {
    background-color: 
    #363636 !important;
    color: white !important;
}


.Container_navbar {
    background-color: #1E1E1E;
    color: #fff;
    margin: 15px;
    width: 1124px;
    height: 64px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30xp;
}

.Container_navbar .main {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.Container_navbar .main h2 {
    align-items: center;

}

.Container_navbar .main .navbar-left {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #E1E1E1;

}

.Container_navbar .main .navbar-right {
    display: flex;

}
.underLine-profile{
    text-decoration: none !important;
}
.Container_navbar .main .profile {
    display: flex;
    align-items: center;
    width: 237px;
    height: 58px;
    margin-right: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    justify-content: space-around;
}

.Container_navbar .main .profile2 {
    display: flex;
    align-items: center;
    width: 237px;
    height: 58px;
    margin-right: 1rem;
    background: transparent;
    border-radius: 8px;
    justify-content: flex-end;
}

/* .Container_navbar .main .profile p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #E1E1E1;
} */
.compltete{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #E1E1E1;
}


 .rupees {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Container_navbar .main .rupees img {
    width: 38px;
    height: 38px;
}

/* .Container_navbar .main .rupees p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #A7A5A4;
    padding-left: 6px;
} */

.credit{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #A7A5A4;
    padding-left: 6px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.Container_navbar .main .notification {
    width: 28px;
    height: 28px;
    margin-left: 1rem;
    padding-top: 1px;
    margin-right: 1rem;
}

.Container_navbar .main .notification svg {

    font-size: 1.8rem;
    margin-top: 0.8rem;

}

.Container_navbar .main .avtar-details {
    display: flex;
    align-items: center;
    
}

.Container_navbar .main .avtar-details h5 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #E1E1E1;
    padding-right: 5px;
}

.Container_navbar .main .avtar-details p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #A7A5A4;
    padding-right: 10px;
}

.Container_navbar .main .avtar-details .avtar-img {
    width: 38px;
    height: 38px;
    cursor: pointer;

}
.Container_navbar .main .avtar-details .avtar-logout img{
    /* margin-top: 7px; */
    cursor: pointer;
}
.Container_navbar .main .profile a img{
    margin-top: 7px;
    cursor: pointer;
}

.Container_navbar .main .profile2 a img{
    margin-top: 7px;
    cursor: pointer;
}


@media screen and (max-width: 420px) {

    .Container_navbar {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .MuiContainer-root {
        padding-top: 0.8rem;
        justify-content: space-between;
    }

    .Container_navbar .main .navbar-left {
        display: none;
    }

    .Container_navbar .main .profile {
        display: none;
    }
    .Container_navbar .main .profile2 {
        display: none;
    }

    .Container_navbar .main .avtar-details h5 {
        display: none;
    }

    .Container_navbar .main .avtar-details p {
        display: none;
    }

    .Container_navbar .main .notification svg {
        margin-top: 0;

    }

}

@media screen and (max-width:900px) {

    .Container_navbar {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .rupees {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
    }
    .credit{
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: #A7A5A4;
        padding-left: 6px;
        margin-right: 12px;
        display: flex;
        margin-top: 12px;
    }

    .MuiContainer-root {
        padding-top: 0.8rem;
        justify-content: space-between;
    }

    .Container_navbar .main .navbar-left {
        display: none;
    }

    .Container_navbar .main .profile {
        display: none;
    }
    .Container_navbar .main .profile2 {
        display: none;
    }
    .Container_navbar .main .avtar-details h5 {
        display: none;
    }

    .Container_navbar .main .avtar-details p {
        display: none;
    }

    .Container_navbar .main .notification img {
        padding-top: 0px;

    }

    .MuiBox-root {
        font-size: 1.8rem;
    }

    .Container_navbar .main .notification svg {
        margin-top: 0;

    }
    .MuiContainer-root{
        max-width: none;
    }
}

@media screen and (min-width:900px) {
    .Container_navbar .main .toggle-icon {
        display: none;
    }
}
@media only screen and (min-device-width: 900px) and (max-device-width:1200px){
    .Container_navbar .main .navbar-left{
        font-size: 20px;
    }
    .avtar-details {
        display: flex;
        align-items: center;
        margin-right: -1rem;
    }
  
.Container_navbar .main .notification{
        margin-right: 2px;
    }
       
}