.book{
    text-transform: capitalize !important;
    background: #E7FFFB !important;
    color:  #4DCAB4 !important;
    font-size: 20px  !important;
    font-family: 'Montserrat' !important;
    font-weight: 700  !important;
    border-radius: 16px !important;
    height: 54px !important;

}

.book:hover{
    background-color: #E7FFFB !important;
}

.upcomingConcerts .slick-dots li button:before {
    font-family: 'slick' !important;
    font-size: 6px !important;
    line-height: 20px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 8px !important;
    height: 8px !important;
    content: '•' !important;
    text-align: center !important;
    opacity: 1 !important;
    color: white !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

.upcomingConcerts .slick-dots li.slick-active button:before {
    opacity: .75 !important;
    color: transparent !important;
    display: inline-block !important;
    width: 8px !important;
    height: 8px !important;
    margin: 5px !important;
    text-indent: -999px !important;
    cursor: pointer !important;
    background-color: #0009 !important;
    background-color: rgba(0,0,0,0) !important;
    border: 2px solid #fff !important;
    border-radius: 10px !important;
}

/* .upcomingform .css-ume8vi-MuiInputBase-input-MuiInput-input {
    color: black !important;
    font-family: 'Open Sans';
  } */

  .upcomingprogram .css-17t2ui2-MuiTypography-root-MuiDialogTitle-root {
    color: black !important;
    font-family: 'Open Sans' !important;
  }

  .upcomingform .css-1r0gjst-MuiInputBase-input-MuiInput-input {
    color: black !important;
    font-family: 'Open Sans' !important;
  }