.paper-form-forgot{
    border: 0 solid transparent !important;
    border-radius: 0.25rem !important;
    box-shadow: 0 0.5rem 1rem 0 #1a1f33 !important;
    /* color: #ffffff !important; */
    font-family: "Open Sans", sans-serif !important;
    font-size: 0.9375rem !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
    margin-bottom: 1.875rem !important;
    background-color: #ffffff !important;
    max-width: 800px !important;
}
.submit-btn{
    background-color: #fb8e0b!important;
    border: transparent!important;
    color: white !important;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.4375rem 0.875rem
}
.gotologin{
    color: #a16eff !important;
    font-size: inherit !important;
    font-style: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    cursor: pointer !important;
    text-decoration: none;
    /* color: yellow !important; */
}
.AlreadyHaveAnAccount{
    color: #000 !important;
    /* color: #a16eff !important; */
    font-size: inherit !important;
    font-style: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    cursor: pointer !important;
    text-decoration: none;    
}
.forgot-data{
    color: #000 !important;
    font-size: 1.875rem  !important;
    font-family: "Open Sans", sans-serif  !important;
    font-weight: 700  !important;
    line-height: 2.5rem  !important;
}