
#psd-card{
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0px 90px;
}



#psd-card-box{
    padding: 70px;
    width: 100%;
}

#pwd-typography{
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-family: DM Sans;
    font-weight: 700;
    margin: 8px 0px;
}

#psd-button{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 10px;
   
}

@media screen and (max-width:600px) {
    #psd-card{
        margin:  0px 10px !important;
    }
    #psd-card-box{
        padding: 10px !important;
    }
}