.homemanageBoldText {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: rgba(23, 23, 23, 0.9);
    margin-bottom: 30px;
  }
  @media (max-width: 580px) {
    .homemanageBoldText {
      font-size: 30px;
      line-height: 40px;
    }
  }
  
  .homemanageNormalText {
    font-family:'Inter', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #6d7280;
    margin-bottom: 16px;
  }
  @media (max-width: 580px) {
    .homemanageNormalText {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .manageimgBox {
    background: #ffffff !important;
    border: 0.484509px solid #cbcbcb !important;
    box-shadow: 0px 1.93804px 9.69018px rgba(119, 198, 242, 0.4) !important;
    border-radius: 9.69018px !important;
    padding: 0px 20px 30px 20px !important;
  }
  @media (max-width: 480px) {
    .manageimgBox {
      padding: 0px 10px 30px 10px !important;
    }
  }
  
  .manageBlueDotContainer {
    height: 30px !important;
  }
  
  .manageimgBox img {
    border-radius: 8px !important;
  }
  
  .manageBlueDot {
    width: 8.04px;
    height: 8.04px;
    background: #0563f1;
    box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  
  .manageDot {
    width: 8.04px;
    height: 8.04px;
    box-shadow: inset 0px 1.60823px 1.60823px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  