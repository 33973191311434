@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Anton&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

.antonFont {
  font-family: "Anton";
}
body {
  font-family: "DM Sans", sans-serif !important;

  font-family: "Inter", sans-serif;
}
code {
  font-family: "DM Sans", sans-serif !important;
}

.App {
  text-align: center;
}

.textStroke {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dmt-font {
  font-family: "DM Sans", sans-serif !important;
}
.tabs-indicator {
  background-color: transparent !important;
 
}
.css-1wf8b0h-MuiTabs-flexContainer {
  background: rgba(241, 241, 250, 0.05);
  border-radius: 32px;
  width: 30%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.css-1g09l29-MuiButtonBase-root-MuiPickersDay-root {
  background-color: orange !important;
}
.css-1g09l29-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled {
  background-color: black !important;
  opacity: 0.6 !important;
  font-weight: 700 !important ;
}

.App-link {
  color: #61dafb;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  color: white;
  background: #141414;
  height: 100vh;
} */
.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px;
}
.main-container .navbar {
  height: 18vh;
  width: 97%;
}

.light-theme-btn {
  background-color: #fb8e0b !important;
  color: white !important;
}
.light-theme-btn:hover {
  border: 1px solid #fb8e0b !important;
}

.custom-dmt-container {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@media screen and (max-width: 600px) {
  .custom-dmt-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.stepperBtn {
  margin-top: 20px !important;
  justify-content: end !important;
  display: flex !important;
  margin-bottom: 50px !important;
}

.music-loader {
  display: flex !important;
  justify-content: center !important;
  height: 80vh !important;
  align-items: center;
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1e1e1e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1e1e1e;
}
.paymentBtn{
  background-color: #fb8e0b !important;
  color: #1e1e1e !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border-radius: 39px !important;
  margin-top: 30px !important;
  height: 44px !important;
}

.copyIcon {
  cursor: pointer;
  margin-left: 7px;
  color: inherit;
  transition: all 0.3s linear

} 
.copyIcon:hover {
  transform: scale(1.3);
  color: #fb8e0b;
}
