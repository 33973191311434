.paper-form-signup {
  border: 0 solid transparent !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0.5rem 1rem 0 #1a1f33 !important;
  /* color: #ffffff !important; */
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.9375rem !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
  margin-bottom: 1.875rem !important;
  background-color: #ffffff !important;
  max-width: 1000px !important;
}
.submit-btn {
  background-color: #fb8e0b !important;
  border: transparent !important;
  color: white !important;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.4375rem 0.875rem;
}
.signup {
  color: #a16eff;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: pointer;
}
.checkbpx-data {
  font-family: "Open Sans, sans-serif" !important;
  font-size: 0.8125rem !important;
  font-weight: 600 !important;
  line-height: 1rem !important;
  color: #000 !important;
}
.already-account {
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: inherit !important;
  cursor: pointer !important;
}
.link-btn-terms {
  color: #b18aff !important;
  text-decoration: none;
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 30px !important;
}
.submit-btn-data {
  color: #a16eff;
  font-size: 13px;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: pointer;
}
#captchaContainerView {
  flex-direction: row !important;
  border-color: #01579b !important;
  border: 1px solid black !important;
  padding: 15px !important;
  background-color: #e1f5fe !important;
  width: 30%;
  margin-top: 20px;
}
.PhoneInputInput{
  height: 40px !important;
}
.PhoneInputCountry{
  width: 40px !important;
  border: 1px solid !important;
}
.PhoneInputCountryIcon--border {
  margin-left: 5px !important;
}
@media screen and (max-width: 968px) {
  #captchaContainerView {
    flex-direction: row !important;
    border-color: #01579b !important;
    border: 1px solid black !important;
    padding: 15px !important;
    background-color: #e1f5fe !important;
    width: 92% !important;
    margin-left: 18px !important;
    margin-top: 20px !important;
  }
}
