* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tracks {
  border-radius: 16px !important;
  background-color: #1E1E1E;
  padding: 30px;
  margin: 28px 0px 32px 0px;
}

@media screen and (max-width: 599px) {
  .tracks {
      padding: 16px;
      margin: 28px 0px;
  }

  .amet {
      font-size: 8px !important;
      line-height: 10px !important;
  }

  .only {
      font-size: 12px !important;
      line-height: 16px !important;
      margin-left: 8px !important;
  }

  .social {
      margin-left: 8px !important;
  }
}

.free1{
  color: #fab511 !important;
}
.free{
  color: #33D67C !important;
  background: rgba(51, 214, 124, 0.05) !important;
  padding: 6px 20px !important;
border-radius: 33px !important;
}
.all {
  font-family: DM Sans !important;
  font-weight: 700 !important;
  font-style: normal;
  color: #ffffff;
}

.sample {
  padding: 20px;
  border-radius: 16px !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.before {
  margin-bottom: 12px !important;
  font-weight: 700 !important;
  color: #FFFFFF;
  font-style: normal;
  font-family: DM Sans !important;
}

.amet {
  font-weight: 400 !important;
  color: #A7A5A4;
  font-style: normal;
  font-family: DM Sans !important;
  font-size: 12px;
  line-height: 16px;
}

.share {
  padding: 0px !important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}

.title {
  font-weight: 700 !important;
  font-family: DM Sans !important;
  font-style: normal;
  color: #FFFFFF;
}

.only {
  font-weight: 700 !important;
  font-family: DM Sans !important;
  font-style: normal;
  background: linear-gradient(180deg, rgba(250, 201, 21, 0.05) 0%, rgba(251, 144, 11, 0.05) 100%);
  padding: 5px 20px;
  border-radius: 33px;
  color: #fab511;
  font-size: 14px;
  line-height: 18px;
}

.social {
  font-weight: 700 !important;
  color: #33D67C;
  padding: 5px 20px;
  background-color: rgba(51, 214, 124, 0.05);
  border-radius: 33px;
  text-transform: capitalize;
  font-family: DM Sans !important;
  font-style: normal;
}