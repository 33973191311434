.free-show-box {
  background-image: url("../../../assets/img/profile/freeservice.png");
  padding: 3rem;
  background-size: cover;
  padding-bottom: 20px;
}
.paper-freeshow {
  background: linear-gradient(180deg, #fac915 0%, #fb900b 100%) !important;
  border-radius: 32px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.9375rem !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
  margin-bottom: 1.875rem !important;
  max-width: 800px !important;
  height: 194px !important;
  color: white !important;
}
.profilefree {
  border: 1px solid #0d0d0d !important;
  color: black !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding: 30px 55px;
  font-family: "DM Sans", sans-serif !important;
  border-radius: 55px !important;
  min-width: 250px !important;
  height: 42px !important;
  margin-top: 30px !important;
}
@media (max-width: 580px) {
  .paper-freeshow {
    background: linear-gradient(180deg, #fac915 0%, #fb900b 100%) !important;
    border-radius: 32px !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
    margin-bottom: 1.875rem !important;
    width: 100% !important;
    height: 250px !important;
    color: white !important;
  }
  .profilefree {
    border: 1px solid #0d0d0d !important;
    color: black !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: "DM Sans", sans-serif !important;
    border-radius: 55px !important;
    min-width: 180px !important;
    height: 46px !important;
    margin-top: 30px !important;
    padding: 20px 35px;
  }
  .maincontainer {
    margin-left: 20px;
    margin-right: 20px;
  }
  .free-show-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
