.mainContainer{
    padding: 30px !important;
    border-radius: 16px !important;
    margin-top: 30px !important;
}
.mainHeading {
    font-size: 24px !important;
    line-height: 31px !important;
    font-weight: 700 !important;
    font-family: DM Sans !important;
}

.firstCheckbox {
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
    margin-left: -12px !important;
}

.selectAll {
    font-size: 18px !important;
    line-height: 23px !important;
    font-weight: 700 !important;
    font-family: DM Sans !important;
}

.mainGridBox {
    display: flex !important;
    align-items: center !important;
    margin-top: 32px !important;
    width: auto !important;
    max-width: 245px !important;
    background: rgba(255, 255, 255, 0.05) !important;
    padding: 6px 11px 6px 11px !important;
    border-radius: 8px !important;
}
.image{
    
    margin-left: 24px !important;
    display: inherit;
   
}

@media screen and (max-width:600px) {
    .mainContainer{
        padding: 16px !important;
    }
   .mainGridBox{
    max-width: 100% !important;
    padding: 4px 7px 4px 7px !important;
   }
   .image{
    margin-left: 14px !important;
    width: 75px !important;
   }

    
}
@media screen and (max-width:350px) {
    .image{
        margin-left: 0px !important;
    }
}