.master-feature {
    background-image: url("../../assets/img/static/features.png");
    min-height: 500px;
    padding-top: 1rem;
    background-size: cover
  }
  .img-block{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    border: 1px solid #dee2e6!important;
    padding: 0.5rem !important;
    border-radius: 0.25rem!important;
    text-align: center !important;
  }
  .heading-feature{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 60px !important;
    text-align: center !important;
    text-transform: capitalize !important;
    color: rgba(23,23,23,.9) !important;
  }
  .feature-text{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 150% !important;
    color: #111827 !important;
  }