#wraper-container {
    background: #1e1e1e !important;
}



#grid-master-container {
    margin-top: 4px;
}


#bfText-label{
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-family: DM Sans;
    font-weight: 700;
    margin-bottom: 8px
}

.bfPage-documentArrenger{
   padding: 10px;
}

#upLoadFile-Lable{
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-family: DM Sans;
    font-weight: 700;
    margin-bottom: 8px
}

#pwd-textfield{
    max-width: 700px !important;
}