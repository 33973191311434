@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');
:root{
  --toastify-color-success : #f9a40d !important;
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans', sans-serif !important;
}
