.button-nav {
  border-radius: 55px !important;
  width: 112px !important;
  height: 42px !important;
  margin-top: 30px !important;
  text-decoration: none !important;
}
.button-nav:hover {
  background-color: #f9a40d !important;
  border: none !important;
  color: black !important;
  border-radius: 55px !important;
  /* width: 112px !important;  */
  height: 42px !important;
  margin-top: 30px !important;
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
}
.deshboard-view {
  display: block !important;
}
.mobile-view-contact{
  display: none !important;
}
@media (max-width: 820px) {
  .deshboard-view {
    display: none !important;
  }
  .mobile-view-contact{
    display: flex !important;
  }
}
