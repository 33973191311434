.master-tune {
    /* background-image: url("../../assets/img/static/features.png"); */
    min-height: 500px;
    padding-top: 1rem;
    background-size: cover
  }
  .heading-feature-top{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 60px !important;
    text-align: center !important;
    text-transform: capitalize !important;
    color: rgba(23,23,23,.9) !important;
  }
  .heading-feature-text{
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center !important;
    letter-spacing: .2px !important;
    color: #6d7280 !important;
  }
 .offering-suite{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    text-transform: capitalize !important;
    color: rgba(23,23,23,.9) !important;
 }
 .services-points{
    color: #111827;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
 }
 .services-points-para{
    font-family: "DM Sans";
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #6d7280!important;
 }
 .services-points-heading{
    color: #111827 !important;
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 600 !important;
 }
 .rounded-img{
    box-sizing: border-box;
    background: #cde1ff;
    border-radius: 50%;
    padding: 20px;
    height: 100%;
 }
 .hr-break{
    box-sizing: content-box !important;
    height: 0 !important;
    overflow: visible !important;
    color: rgba(0, 0, 0, 0.1) !important;
    opacity: 0.2 !important;
 }
 
 