hr.footer-profile {
  border: 1px solid #d4d4d8 !important;
  opacity: 0.5;
  margin-left: 20px;
  margin-right: 20px;
}
.link-btn-profile {
  color: white !important;
  text-decoration: none;
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}
