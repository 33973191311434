.galleryImage{
    min-height:305px;
}

@media screen and (max-width:1200px) {
    .galleryImage{
        min-height:285px;
    }
    
}

@media screen and (max-width:900px) {
    .galleryImage{
        min-height:265px;
    }
    
}

@media screen and (max-width:600px) {
    .galleryImage{
        min-height:225px;
    }
    
}

@media screen and (max-width:400px) {
    .galleryImage{
        min-height:185px;
    }
    
}