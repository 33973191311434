.sell-number-banner {
  background-image: url("../../assets/img/static/Livent\ Events\ Website.png");
  background-size: cover;
}
.uploadimagesell {
  width: 100%;
  /* height: auto !important; */
  border-radius: 5px;
}
.sellmusichead{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.innermusic {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 3rem !important;
  line-height: 150% !important;
}
.Number-item-label {
  text-align: center;
  margin-bottom: 18px;
}
.Number-item-music {
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: 'DM Sans';
    font-size: 21px;
}
