.hire-event-data2 {
    background-color: #FCE23B !important;
    color: #BE0081 !important;
    font-size: 16px !important;
    /* max-width: 217px; */
    height: 48px;
    font-family: "Ranchers", sans-serif !important;
    rotate: 3deg;
    border: 2px solid #000000 !important;
    margin-left: 10px !important;
    text-transform: capitalize !important;
  }

  .rappergirl{
    width:100%;
    height: 100%;
  }

  .concertslider .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 11px;
    height: 11px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #FF599D;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.concertslider .slick-dots li.slick-active button:before {
    opacity: .75;
    color: transparent;
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 4px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #0009;
    background-color: rgba(0,0,0,0);
    border: 2px solid #FF599D;
    border-radius: 10px;
}
  