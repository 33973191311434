.custom-containdata-second {
  /* background-image: url("../../../assets/img/profile/bannersecond.png") !important; */
  background: #FCE23B;
  background-size: cover !important;
  height: 100% !important;
  position: relative;
}
.banner_backg{
  background-image: url(../../../assets/img/banner_back.png);
  background-size: 100% 100% !important;
  width: 100%;
  height: 560px;
}
.banner_img{
  width: 300px;
  height: 300px;
}
.homemanageBoldTextaboutsecond {
  font-family: "Anton", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 103px;
  /* line-height: 125px; */
  text-transform: uppercase;
  color: #00d8c0;
  margin-bottom: 30px;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}
.homemanageNormalTextsecond {
  font-family: "Anton";
  font-size: 16px;
  line-height: 32px;
  color: #141414;
  font-family: "Anton", sans-serif !important;
  text-transform: capitalize !important;
  max-width: 90% !important;
}
.hire-event {
  background-color: #ff599d !important;
  color: #fff000 !important;
  font-size: 16px;
  width: 217px;
  height: 48px;
  font-family: "Ranchers", sans-serif !important;

  rotate: 3deg;
  border: 2px solid #000000 !important;
}
.hire-section {
  margin-top: 40px !important;
}
.hire-event-data {
  background-color: transparent !important;
  color: #ff599d !important;
  font-size: 16px;
  width: 217px;
  height: 48px;
  font-family: "Ranchers", sans-serif !important;
  rotate: 3deg;
  border: 2px solid #000000 !important;
  margin-left: 10px !important;
}

@media screen and (max-width:1050px) {
  .homemanageBoldTextaboutsecond {
    font-family: "Anton", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
   
    /* line-height: 1px; */
    text-transform: uppercase;
    color: #00d8c0;
    margin-bottom: 30px;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
  }
}

@media (max-width: 780px) {
  .custom-containdata-second {
    /* background-image: url("../../../assets/img/profile/bannersecond.png") !important; */
    background-size: cover !important;
    height: 100% !important;
    z-index: 1 !important;
  }
  .second-port-text-data {
    z-index: 9999999 !important;
  }
  .hire-event {
    background-color: #ff599d !important;
    color: #fff000 !important;
    font-size: 16px !important;
    width: 217px;
    height: 48px;
    font-weight: 400 !important;
    font-family: "Anton", sans-serif !important;
    margin-top: 0px !important;
    rotate: 3deg;
    border: 2px solid #000000 !important;
  }
  .hire-event-data {
    background-color: transparent !important;
    color: #ff599d !important;
    font-size: 16px;
    width: 217px;
    height: 48px;
    font-family: "Anton", sans-serif;
    margin-top: 20px !important;
    rotate: 3deg;
    border: 2px solid #000000 !important;
    margin-left: 10px !important;
  }
  .homemanageBoldTextaboutsecond {
    font-family: "Anton", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    /* line-height: 125px; */
    text-transform: uppercase;
    color: #00d8c0;
    margin-bottom: 30px;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
  }
}

@media screen and (max-width:500px) {
  .banner_img{
    width: 230px;
    height: 230px;
  }
}




.editFormBtnsecond{
  background-color: #00D8C0 !important;
  border: none !important;
  border-radius: 60px !important;
  color: white !important;
  font-weight: 600 !important;
}