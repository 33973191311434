.slick-slider {

    z-index: 100;
}

.montu{
    font-family: 'Monoton' !important;
}

.admireSlider .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 11px;
    height: 11px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #FCE23B;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.admireSlider .slick-dots li.slick-active button:before {
    opacity: .75;
    color: transparent;
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 5px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #0009;
    background-color: rgba(0,0,0,0);
    border: 2px solid #FCE23B;
    border-radius: 10px;
}

.admierBox{
    width: 296px !important;
}
.admiredesc{
    left: 50px;
}

@media screen and (max-width:400px) {
    .admierBox{
        width: 246px !important;
    }
    .admiredesc{
        left: 30px;
    }
}