.master-tune {
  /* background-image: url("../../assets/img/static/features.png"); */
  min-height: 500px;
  padding-top: 1rem;
  background-size: cover;
}
.heading-feature-top-data {
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 40px !important;
  line-height: 60px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  color: rgba(23, 23, 23, 0.9) !important;
}
.heading-feature-text-data {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  color: #6d7280 !important;
}
@media screen and (max-width: 456px) {
  .heading-feature-top-data {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  .heading-feature-text-data{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    line-height: 25px !important;
    font-size: 15px !important;
    text-align: center !important;
    font-weight: 300 !important;
    color: #6d7280 !important;
    font-family: "Inter" !important;
  font-style: normal !important;
  }
}
