.listen{
    font-size: 20px !important;
    font-family: 'Montserrat'  !important;
    text-transform: capitalize !important;
    background-color: #4DCAB4 !important;
    color: white !important;
    border-radius: 16px !important;
    font-weight: 500 !important;
    min-width: 300px !important;
    height: 70px !important;
    margin-left: 20px !important;
}
.listen:hover{
background-color: #4DCAB4 !important;
}

.listen2{
    font-size: 20px !important;
    font-family: 'Montserrat' !important;
    text-transform: capitalize  !important;
    background-color: transparent !important;
    border: 1px solid #4DCAB4 !important;
    color: #4DCAB4 !important;
    border-radius: 16px !important;
    font-weight: 500 !important;
    min-width: 300px !important;
    height: 70px  !important;
    margin-left: 20px !important;
}
.listen2:hover{
    background-color: transparent  !important;
    border: 1px solid #4DCAB4  !important;
    }

    @media screen and (max-width:687.5px) {
        .listen2{
            margin-top: 20px  !important;
        }
    }