.concert2 .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #4DCAB4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.concert2 .slick-dots li.slick-active button:before {
    opacity: .75;
    color: transparent;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 5px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #0009;
    background-color: rgba(0,0,0,0);
    border: 2px solid #4DCAB4;
    border-radius: 10px;
}