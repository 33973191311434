.lips{
    margin-left: 30px;
    position: absolute;
    top: 54px;
    /* text-align: end; */
    /* right: 190px; */
}
@media screen and (max-width:410px) {
    .audiotag{
        width: 100% !important;
    }   
}

.quality2 {
    color: white !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-top: 12px !important;
    line-height: 15px !important;

    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    max-width: 344px;
}
.basic-btn2 {
    background-color: #00D8C0 !important;
    border: none !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: 'DM Sans', sans-serif !important;
    border-radius: 15px !important;
    width: 149px !important;
    height: 28px !important;
    margin-top: 12px;
}


.listeslider .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 11px;
    height: 11px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #FCE23B;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.listeslider .slick-dots li.slick-active button:before {
    opacity: .75;
    color: transparent;
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 4px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #0009;
    background-color: rgba(0,0,0,0);
    border: 2px solid #FCE23B;
    border-radius: 10px;
}