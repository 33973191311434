.splash-text-input{
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    padding: 8px !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color:white !important;
    border-radius: 5px !important;
    height: 48px !important;
}
.splash-text-input:focus{
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    padding: 8px !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color:white !important;
    border-radius: 5px !important;
    height: 48px !important;
}

.splash-text-input option{
    background-color: #343434  !important;
    color:white !important;
    margin-bottom: 18px !important;
}

.splash-submit{
    background-color:  #f9a40d !important ;
    color: black !important;
    border-radius: 40px !important;
    width: 100px !important;
    height: 40px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;

}

.splash-text{
    font-size: 16px !important;
     font-weight: 600 !important;
     color: white !important;
     font-family: 'DM Sans', sans-serif !important;
     margin-bottom: 5px !important;

}

.splash-text-area{
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    padding: 8px !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color:white !important;
    border-radius: 5px !important;
}

.fontBtn{
    width: 50px !important;
    height: 48px !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color:white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 4px !important;
}

.searchSplash{
    background-color:  #f9a40d !important ;
    color: black !important;
    border-radius: 40px !important;
    width: 70px !important;
    height: 40px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

