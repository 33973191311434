.artistbtn {
    background-color:#f9a40d  !important;
    color: #0D0D0D !important;
    padding: 10px, 22px, 10px, 22px !important;
    border-radius: 24px !important;
    /* //styleName: Body/B4 Bold; */
    font-family: DM Sans !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    border: none !important;
    /* letter-spacing: 0em !important;
    text-align: center !important; */

}
.artistbtn:hover{
    border: none !important;

    background-color: #f9a40d  !important;
    color: #0D0D0D !important;
    padding: 10px, 22px, 10px, 22px !important;
    border-radius: 24px !important;
    /* //styleName: Body/B4 Bold; */
    font-family: DM Sans !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    /* letter-spacing: 0em !important;
    text-align: center !important; */
}
.ringtone {
    color: #A7A5A4 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    /* margin-top: 12px !important; */
    /* line-height: 15px !important; */
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.drop-song1 {
    font-size: 18px !important;
    font-weight: 700 !important;
    margin-top: 10px !important;
    margin-bottom: 8px !important;
    line-height:12px !important;
    text-align: center !important;
    padding: 0px 15px 0px 15px !important;

}

.editbtnsongalbum{
    background-color: #f9a40d !important;
    border: none !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    font-family: 'DM Sans', sans-serif !important;
    border-radius: 15px !important;
    width: 119px !important;
    height: 26px !important;
    margin-top: 12px;
    text-align: end !important;
}

.album {
    font-size: 16px !important;
    line-height: 21px !important;
}