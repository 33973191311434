.change_art{
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    color: #0D0D0D !important;
    border: 1px solid #fac915 !important;
    border-radius: 24px !important;
    font-family: DM Sans;
    font-size: 14px !important;
    line-height: 14px !important;
    font-weight: bold !important;
    width: 200px !important;
    height: 44px !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    text-transform: capitalize !important;
}

.splash-div{
    top:50% !important;

}

@media screen and (max-width:1200px) {
    .splash-div{
      
       
    }
}

@media screen and (max-width:600px) {
    .splash-div{
    /* top:70% !important; */
       height: 1400px !important;
    }
}