
  .uploadimage {
    width: 100%;
    height: 230px;
    border-radius: 5px;
  }
  .sellartisthead{
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px!important;
    line-height: 60px!important;
    text-align: center;
    text-transform: capitalize;
    color: #2f2f2f;
  }
  .sellartist {
    color: #6d7280!important;
    font-family: "Inter" !important;
    font-style: normal !important;
    font-size: 1.25rem !important;
    text-align: center;
  }
  .Number-item-label {
    text-align: center;
  }
  .Number-item-label-artist {
      color: white;
      text-align: center;
      font-weight: bold;
      font-family: 'DM Sans';
      font-size: 21px;
  }
  @media (max-width: 480px) {
    .sellartisthead {
     font-size: 28px !important;
    }
    .sellartist {
        font-size: 18px !important;
    }
  }