.paper-form-contact2 {
    border: 0 solid transparent !important;
    border-radius: 0.25rem !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 0.9375rem !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
    margin-bottom: 1.875rem !important;
    background-color: #FCE23B !important;
    max-width: 800px !important;
    color: white !important;
  }
  .submitprofilebtn {
    background-color: #f9a40d !important;
    border: none !important;
    color: black !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    font-family: "DM Sans", sans-serif !important;
    border-radius: 55px !important;
    width: 112px !important;
    height: 42px !important;
    margin-top: 30px !important;
  }
  /* .contact-data {
    font-family: "DM Sans" !important;
    font-weight: 700;
  } */
  
  .social-second-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
  }
  @media (max-width: 650px) {
    .social-second-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
    }
  }
  

  /* .contactFormthird .css-ume8vi-MuiInputBase-input-MuiInput-input {
    color: black !important;
    font-family: 'Open Sans';
  } */

  .hire-event2 {
    background-color: #ff599d !important;
    color: #fff000 !important;
    font-size: 16px;
    width: 217px;
    height: 48px;
    font-family: "Ranchers", sans-serif !important;
  

    border: 2px solid #000000 !important;
  }

  .contactSumbit{
    font-size: 20px !important;
    font-family: 'Montserrat'  !important;
    text-transform: capitalize !important;
    background-color: #4DCAB4 !important;
    color: white !important;
    border-radius: 16px !important;
    font-weight: 500 !important;
    min-width: 300px !important;
    height: 70px !important;
    margin-left: 20px !important;
}
.contactSumbit:hover{
background-color: #4DCAB4 !important;
}