.trust-client{
    font-family: "DM Sans" !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    text-align: center !important;
    color: gray!important;
    background-color: #fff !important;
    text-transform: uppercase;
}
.musicapp{
    overflow: hidden;
}