/*  wallet card css */
.yourwallet{
    font-size: 1.875rem !important;
    font-family: Open Sans, sans-serif !important;
    font-weight: 700 !important;
    line-height: 2.5rem !important;
    text-align: center !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0 !important;
}
.totalcard{
    font-size: 1.625rem !important;
    font-family: Open Sans, sans-serif !important;
    font-weight: 700 !important;
    line-height: 2rem !important;
    text-align: center !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0 !important;
}
.card{
    padding: 2.25rem 2.25rem 0.75rem !important;
    background-color:"black" !important;
    box-sizing: border-box !important;
    border: 0 solid transparent !important;
    border-radius: 0.25rem; 
    margin-bottom: 1.875rem !important;
}
/* payment history css */
.paymentcard{
    padding: 1rem 1.25rem !important;
    background-color:"black" !important;
    box-sizing: border-box !important;
    border: 0 solid transparent !important;
    border-radius:  8px !important; 
    margin-bottom: 1.875rem !important;
    
}
.account{
    font-size: 1.375rem !important;
    font-family: Open Sans, sans-serif !important;
    font-weight: 700 !important;
    line-height: 2rem !important;
    text-align: right !important;
    padding: 10px;
}
.masteraudiolist{
    /* padding: 1rem 1.25rem !important; */
    background-color:"black" !important;
    box-sizing: border-box !important;
    border: 0 solid transparent !important;
    border-radius:  8px !important; 
    margin-bottom: 1.875rem !important;
    
}