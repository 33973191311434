* {
  -ms-overflow-style: none;
}

.container {
  box-sizing: border-box;
  /* height: 100vh; */
  width: 100vw;
  display: flex;
  position: relative;
}

.container .sidebar {
  height: 170vh;
  background: #1e1e1e;
  z-index: 1000;
  padding-right: 6px !important;
  width: 320px !important;
}

.bottom-section {
  position: relative;
  height: 80vh;
  width: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.link {
  cursor: pointer !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 28px;
  margin-left: 15px;
  width: 100%;
  padding: 10px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 21px;
  margin-top: 15px;
  height: 42px;
}

.link.active {
  background: linear-gradient(180deg, #fac915 0%, #fb900b 100%) !important;
  color: #1e1e1e !important;
  font-weight: 700 !important;
  width: 90% !important;
}

link:hover {
  background: linear-gradient(180deg, #fac915 0%, #fb900b 100%);
  transition: all 0.3s;
  border-radius: 8px;
}

.icon {
  transform: translateY(12%);
  color: white !important;
}

.name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #e1e1e1;
}

.link.active > .icon {
  color: #1e1e1e !important;
}
.link.active > .name {
  color: #1e1e1e !important;
  font-weight: 600;
}

.logo {
  margin-top: 20px;
  cursor: pointer;
}

.logout {
  margin-top: 20px;
  margin-bottom: 2rem;
  display: flex;
  gap: 15px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 18px;
  color: #e1e1e1;
  margin-left: 33px;
  cursor: pointer;
  align-items: center;
}

.MuiContainer-root {
  max-width: none !important;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu .icon svg {
  font-size: 32px;
  margin-top: 1rem;
}

.sidemenu {
  margin-left: 2.3rem;
}

.dropDownMenu {
  font-size: 1rem;
}

@media screen and (max-width: 420px) {
  .container .sidebar {
    width: 100%;
  }

  .bottom-section {
    width: 100%;
    height: 100%;
  }

  .link.active {
    width: 270px;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  svg {
    cursor: pointer;
  }
}

@media screen and (min-width: 900px) {
}
@media screen and (max-width: 900px) {
  .sidebar {
    position: fixed;
    overflow: scroll;
    left: -500px;
    transition: 0.7s ease-in-out;
  }

  .sidebar.open {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .logo {
    display: flex;
    align-items: center;
  }
  .logo svg {
    margin-left: 2rem;
  }
}
@media screen and (min-width: 900px) {
  .sidebar .top_section .logo svg {
    display: none;
  }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1200px) {
  .bottom-section {
    width: 25vw;
  }
  .Container_navbar .main .avtar-details h5 {
    font-size: 10px;
  }
  .dropDownMenu {
    font-size: 1rem;
  }
}

.text {
  margin-left: 2px !important;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #e1e1e1;
}
