.change {
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    /* / text-align: center !important; / */
    margin: 20px auto !important;
    color: black !important;
    border: 1px solid #fac915 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 700 !important;
}

.pass {
    font-size: 16px;
    font-family: DM sans;
    font-weight: 400 !important;
    margin-top: 10px !important;
}

@media screen and (max-width: 599px){
    .pass {
        /* / font-size: 13px !important; / */
    }
    
    .current {
        padding: 16px !important;

    }
}

.current {
    background-color: #1e1e1e;
    margin: 30px auto;
    padding: 30px !important;
    border-radius: 16px;
}


    
