* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.apply {
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    color: #0D0D0D !important;
    border: 1px solid #fac915 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 700 !important;

}

@media screen and ( max-width: 599px ){
    .apply {
        font-size: 8.41px !important;
        padding: 2px 4px !important;
        border-radius: 2.4px !important;
    }
}

.pto {
    border-radius: 4px !important;
    padding: 8px 20px !important;
    background: linear-gradient(180deg, #FAC915 0%, #FB900B 100%) !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    color: #0D0D0D !important;
    border: 1px solid #fac915 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 700 !important;

}

@media screen and ( max-width: 599px ){
    .pto {
        font-size: 8.41px !important;
        line-height: 10.94px !important;
        padding: 5px !important;
    }
}

@media screen and ( max-width: 1024px ){
    .pto {
        
        padding: 5px !important;
    }
}



.platform {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 16px !important;
    padding: 30px;
    margin: 48px 0px 92px;
}

@media screen and (max-width: 599px){
    .platform {
        padding: 16px !important;
        margin: 28px 0px !important;
    }

    .manzil {
        padding: 12px !important;
        border-radius: 4.80px !important;
    }

    .names {
        font-size: 10.80px !important;
        line-height: 14px !important;
    }    
    
    .price {
        font-size: 9.61px !important;
        line-height: 14px !important;
    }  
}

.bill {
    font-family: DM Sans !important;
    font-weight: 700 !important;
    font-style: normal;
    color: #ffffff;
    font-size: 24px !important;
    line-height: 31px;
}

.manzil {
    background-color:rgba(255, 255, 255, 0.05);
    border-radius: 8px !important;
    padding: 20px;
}

.head {
    font-family: DM Sans !important;
    font-weight: 700 !important;
    font-style: normal;
    color: #ffffff;
}

.names {
    font-family: DM Sans !important;
    font-weight: 700 !important;
    font-style: normal;
    color: #ffffff;
    font-size: 18px;
    line-height: 23px;
}

.price {
    font-family: DM Sans !important;
    font-weight: 400 !important;
    font-style: normal;
    color: #ffffff;
    font-size: 16px;
    line-height: 21px;
}


