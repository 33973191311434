.drop{
    /* border: 2px dashed white !important; */
    border-radius: 4px !important;
    max-width: 100% !important;
    height: 170px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    letter-spacing: 4px;
    background: linear-gradient(to right, #A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #A7A5A4 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#A7A5A4 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 22px 2px, 2px 22px;
  
}

.upload-song{
    background: rgba(255, 255, 255, 0.05) !important;
    text-transform: capitalize !important;
    border-radius: 50px !important;
    font-size: 18px !important;
    height: 33px !important;
    width: 169px !important;
    color: white !important;
    font-weight: 700 !important;
    margin-top: 10px  !important;
}

.drop-song{
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  
}

.support{
color: #A7A5A4 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 12px !important;
}

.demand {
    font-family: dm sans !important;
    font-size: 16px !important;
    line-height: 21px !important;
    font-style: normal !important;
    font-weight: 700 !important;
}